
















import { SPOT_HEALTH_META } from '@/features/domain-ui/spot-health/constants';
import { Point } from '@/features/ui/charts/stacked-horizontal-chart/model';
import { SpotHealth } from '@/types/iot-portal';
import { ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { HealthAggregation } from './aggregation';

const spotHealths = [SpotHealth.CRITICAL, SpotHealth.DEGRADED, SpotHealth.OK, SpotHealth.UNKNOWN, SpotHealth.INACTIVE];

type HealthState = { health: SpotHealth; count: number };

@Component
export default class AggregatedHealthStates extends Vue {
  @ObjectProp(true)
  private readonly aggregations!: HealthAggregation[];

  private get healthStates(): HealthState[] {
    const stateMap = new Map(this.aggregations.map(({ health, count }) => [health, count]));

    return spotHealths.map((health) => ({ health, count: stateMap.get(health) ?? 0 }));
  }

  private get chartPoints(): Point[] {
    return this.healthStates.map(({ count, health }) => ({ value: count, color: SPOT_HEALTH_META[health].color }));
  }
}
