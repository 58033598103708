












import smartClimateImage from '@/features/device-roles/smart-climate-sensor/smart-climate-sensor-lg.svg';
import smartPlugImage from '@/features/device-roles/smart-plug/smart-plug-lg.svg';
import { SpotRole } from '@/types/iot-portal';
import { ObjectProp } from '@/util/prop-decorators';
import { sum } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { AppSmartDeviceAppViewQuery } from '../../views/app/__generated__/AppSmartDeviceAppViewQuery';
import SmartDeviceConsumption from '../smart-device-consumption/SmartDeviceConsumption.vue';
import SmartDeviceStatus from '../smart-device-status/SmartDeviceStatus.vue';
import {
  AppSmartDeviceSpotFragment,
  AppSmartDeviceSpotFragment_metrics_ContinuousMetric_points,
} from './__generated__/AppSmartDeviceSpotFragment';

type ContinuousMetricPoint = AppSmartDeviceSpotFragment_metrics_ContinuousMetric_points;

type DeviceStatus = {
  on: number;
  off: number;
};

@Component({
  components: { SmartDeviceStatus, SmartDeviceConsumption },
  data() {
    return {
      smartPlugImage,
      smartClimateImage,
    };
  },
})
export default class WidgetList extends Vue {
  @ObjectProp()
  private readonly spots!: AppSmartDeviceAppViewQuery['spots'];

  private get smartPlugSpots(): AppSmartDeviceAppViewQuery['spots']['items'] {
    return this.spots.items.filter((item: AppSmartDeviceSpotFragment) => item.role === SpotRole.SMART_PLUG);
  }

  private get smartClimateSpots(): AppSmartDeviceAppViewQuery['spots']['items'] {
    return this.spots.items.filter((item: AppSmartDeviceSpotFragment) => item.role === SpotRole.SMART_CLIMATE_SENSOR);
  }

  private get countSmarPlugs(): DeviceStatus {
    return {
      on: this.smartPlugSpots.filter(({ metrics }) => metrics.length > 0).length,
      off: this.smartPlugSpots.filter(({ metrics }) => metrics.length == 0).length,
    };
  }

  private get countSmarClimateSensors(): DeviceStatus {
    return {
      on: this.smartPlugSpots.filter(({ metrics }) => metrics.length > 0).length,
      off: this.smartClimateSpots.filter(({ metrics }) => metrics.length > 0).length,
    };
  }

  protected get consumptionSmartPlugs(): number {
    const metrics = this.smartPlugSpots.flatMap(({ metrics }) => metrics);

    return this.calculateConsumptionForMetrics(metrics);
  }

  protected get consumptionSmartClimateSensors(): number {
    const metrics = this.smartClimateSpots.flatMap(({ metrics }) => metrics);

    return this.calculateConsumptionForMetrics(metrics);
  }

  private calculateConsumptionForMetrics(
    metrics: AppSmartDeviceAppViewQuery['spots']['items'][number]['metrics'],
  ): number {
    const filteredMetrics = metrics.filter(
      ({ name, __typename }) => ['con', 'temperature'].includes(name) && __typename === 'ContinuousMetric',
    );

    const points = filteredMetrics.flatMap(({ points }) => points as ContinuousMetricPoint[]);

    return sum(points.map(({ continuous }) => continuous));
  }
}
