






















import { SpotRole } from '@/types/iot-portal';
import { Component, Vue } from 'vue-property-decorator';
import query from './spots-health.gql';
import State from './state/State.vue';
import {
  AppSmartDeviceHealthAggregationsQuery,
  AppSmartDeviceHealthAggregationsQueryVariables,
} from './__generated__/AppSmartDeviceHealthAggregationsQuery';

@Component({
  components: { State },
  apollo: {
    spots: {
      query,
      variables(this: AggregatedHealthStates): AppSmartDeviceHealthAggregationsQueryVariables {
        return {
          roles: [SpotRole.SMART_PLUG],
        };
      },
    },
  },
})
export default class AggregatedHealthStates extends Vue {
  private readonly spots?: AppSmartDeviceHealthAggregationsQuery['spots'];
}
