












import { BooleanProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class StatusIndicator extends Vue {
  @BooleanProp()
  private readonly status!: boolean;
}
