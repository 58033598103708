

































import { SpotRole } from '@/types/iot-portal';
import { ApolloQueryResult } from 'apollo-client';
import { Component, Vue } from 'vue-property-decorator';
import view from './view.gql';
import {
  AppSmartDeviceAppViewQuery,
  AppSmartDeviceAppViewQueryVariables,
} from './__generated__/AppSmartDeviceAppViewQuery';

@Component({
  apollo: {
    spots: {
      query: view,
      variables(this: AppView): AppSmartDeviceAppViewQueryVariables {
        return {
          ...this.variables,
        };
      },
      manual: true,
      result(this: AppView, { data }: ApolloQueryResult<AppSmartDeviceAppViewQuery>): void {
        this.spots = data.spots;
      },
    },
  },
  data() {
    return { spots: undefined };
  },
})
export default class AppView extends Vue {
  private spots?: AppSmartDeviceAppViewQuery['spots'];

  private get variables(): AppSmartDeviceAppViewQueryVariables {
    return {
      roles: [SpotRole.SMART_CLIMATE_SENSOR, SpotRole.SMART_PLUG],
      descriptors: [
        {
          start: '2022-12-01T00:00:00.00000Z',
          stop: '2022-12-13T00:00:00.00000Z',
          resolution: { intervalLength: 'P1D' },
        },
      ],
    };
  }

  private get spotIds(): Array<string> {
    return this.spots ? this.spots.items.map((item) => item.id) : [];
  }

  private get loading(): boolean {
    return this.$apollo.queries.spots.loading;
  }
}
