var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-panel',[_c('ui-flex',{attrs:{"stretch":""}},[_c('ui-cell',[_c('ui-input-select',{attrs:{"options":_vm.chartStyleOptions,"option-label":function (ref) {
	var label = ref.label;

	return label;
},"reduce":function (ref) {
	var value = ref.value;

	return value;
},"clearable":false,"placeholder":"Chart Style","pull-right":""},model:{value:(_vm.chartStyle),callback:function ($$v) {_vm.chartStyle=$$v},expression:"chartStyle"}})],1)],1),_c('ui-flex',{attrs:{"stretch":""}},[_c('ui-cell',[_c('ui-dynamic-chart',{key:_vm.chartStyle,staticClass:"chart",attrs:{"sets":_vm.metricData,"chart-style":_vm.chartStyle,"type":_vm.LineChartType.ECHARTS,"smooth":true,"aggregation-interval":"minute"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }