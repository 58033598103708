
























import { IntegerProp, StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import StatusIndicator from './status-indicator/StatusIndicator.vue';

@Component({
  components: { StatusIndicator },
})
export default class SmartDeviceStatus extends Vue {
  @StringProp(true)
  private readonly image!: string;

  @IntegerProp(true)
  private readonly onCount!: number;

  @IntegerProp(true)
  private readonly offCount!: number;
}
