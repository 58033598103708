













import { Component, Vue } from 'vue-property-decorator';
import { Status as StatusType } from '@/features/ui/status-card/model';
import { ArrayProp } from '@/util/prop-decorators';
import query from './alerts.gql';
import {
  AppSmartDeviceAlertsQuery,
  AppSmartDeviceAlertsQueryVariables,
} from './__generated__/AppSmartDeviceAlertsQuery';

@Component({
  apollo: {
    alerts: {
      query,
      variables(this: Alerts) {
        return {
          ...this.variables,
        };
      },
    },
  },
})
export default class Alerts extends Vue {
  private readonly alerts?: AppSmartDeviceAlertsQuery['alerts'];

  @ArrayProp(true)
  private readonly spotIds!: Array<string>;

  private get variables(): AppSmartDeviceAlertsQueryVariables {
    return {
      spotIds: this.spotIds,
    };
  }

  private get status(): StatusType {
    return StatusType.OK;
  }
}
