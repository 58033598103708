















import { StringProp, NumberProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

/* TODO should probably use SpotWidgetCounterControl */
@Component
export default class SmartDeviceConsumption extends Vue {
  @StringProp(true)
  private readonly image!: string;

  @NumberProp(true)
  private readonly value!: number;
}
