
























import { ChartSet, ChartStyle, CHART_STYLE_OPTIONS, LineChartType } from '@/components/charts/model';
import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { formatMetricName } from '@/features/core/util/metric-formatters';
import { normalizeMetricPoint } from '@/features/core/util/metrics';
import { isDef } from '@/util/lang';
import { ObjectProp } from '@/util/prop-decorators';
import { groupBy, partition } from 'lodash';
import { DateTime } from 'luxon';
import { Component, Mixins } from 'vue-property-decorator';
import { AppSmartDeviceAppViewQuery } from '../../views/app/__generated__/AppSmartDeviceAppViewQuery';
import {
  AppSmartDeviceSpotFragment_metrics_ContinuousMetric_points,
  AppSmartDeviceSpotFragment_metrics_DiscreteMetric_points,
} from '../widget-list/__generated__/AppSmartDeviceSpotFragment';

type ContinuousMetricPoint = AppSmartDeviceSpotFragment_metrics_ContinuousMetric_points;
type DiscreteMetricPoint = AppSmartDeviceSpotFragment_metrics_DiscreteMetric_points;

type MetricPointUnion = ContinuousMetricPoint | DiscreteMetricPoint;

const tempMetricName = 'temperature';
const conMetricName = 'con';

@Component({
  data() {
    return { chartStyle: undefined, LineChartType };
  },
})
export default class ConsumptionChart extends Mixins(DeviceRoleMapMixin) {
  @ObjectProp()
  private readonly spots!: AppSmartDeviceAppViewQuery['spots'];

  private chartStyle!: ChartStyle;
  private LineChartType!: LineChartType;
  private chartStyleOptions = CHART_STYLE_OPTIONS;

  private get metricData(): ChartSet[] {
    const filteredWithMetrics = this.spots.items.filter(({ metrics }) => metrics.length > 0);
    const metrics = filteredWithMetrics.flatMap(({ metrics }) => metrics);

    const conFilteredMetrics = metrics.filter(
      ({ name, __typename }) => name === conMetricName && __typename === 'ContinuousMetric',
    );
    const tempFilteredMetrics = metrics.filter(
      ({ name, __typename }) => name === tempMetricName && __typename === 'ContinuousMetric',
    );

    const conPoints = conFilteredMetrics.flatMap(({ points }) => points as ContinuousMetricPoint[]);
    const tempPoints = tempFilteredMetrics.flatMap(({ points }) => points as ContinuousMetricPoint[]);

    return [
      {
        label: formatMetricName(conMetricName),
        name: conMetricName,
        points: this.getGdprMetricPoints(conPoints)
          .map(normalizeMetricPoint)
          .map(({ time, value }) => ({ x: time, y: value })),
      },
      {
        label: formatMetricName(tempMetricName),
        name: tempMetricName,
        points: this.getGdprMetricPoints(tempPoints)
          .map(normalizeMetricPoint)
          .map(({ time, value }) => ({ x: time, y: value })),
      },
    ];
  }

  private getGdprMetricPoints(metricPoints: MetricPointUnion[]): MetricPointUnion[] {
    const now = DateTime.utc();

    const [newerThan2Weeks, olderThan2Weeks] = partition(
      metricPoints,
      ({ time }) => DateTime.fromISO(time).plus({ weeks: 2 }) >= now,
    );

    const pointsGroupedByYearMonth = groupBy(
      olderThan2Weeks,
      ({ time }) => `${DateTime.fromISO(time).year}-${DateTime.fromISO(time).month}`,
    );

    const filteredPointsOlderThan2Weeks = Object.values(pointsGroupedByYearMonth).flatMap((points) =>
      this.filter1thAnd16th(points),
    );

    const gdprPoints = [...newerThan2Weeks, ...filteredPointsOlderThan2Weeks].sort(
      (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime(),
    );

    return gdprPoints;
  }

  private filter1thAnd16th(metricPoints: MetricPointUnion[]): MetricPointUnion[] {
    const firstOfMonth = metricPoints.find(({ time }) => {
      return DateTime.fromISO(time).hasSame(DateTime.fromISO(time).startOf('month'), 'day');
    });

    const sixteenthOfMonth = metricPoints.find(({ time }) => {
      const date = DateTime.fromISO(time);

      return DateTime.fromISO(time).hasSame(DateTime.utc(date.year, date.month, 16), 'day');
    });

    if (firstOfMonth === undefined && sixteenthOfMonth === undefined) {
      return [];
    }

    return [firstOfMonth, sixteenthOfMonth].filter(isDef);
  }
}
